import React from "react";
import {Helmet} from "react-helmet";
import './stype.css';
import Auth from '@aws-amplify/auth';

class Pagar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            myExternalLib: null,
            email: String, 
            sub: String,
            epaycotest: 'true'
        };

        this.handleScriptInject = this.handleScriptInject.bind(this);
    }
    
    onChangeText(key, value) {
    this.setState({
      [key]: value
    });
  }

  async componentDidMount() {
  
  Auth.currentAuthenticatedUser()
    .then(user => {
      this.onChangeText('email', user.attributes.email);
      this.onChangeText('sub', user.attributes.sub);
    })
    .catch(err => console.log(err));
  }


    handleScriptInject({ scriptTags }) {
        if (scriptTags) {
            const scriptTag = scriptTags[0];
            scriptTag.onload = () => {
                // I don't really like referencing window.
                console.log('myExternalLib loaded!', window.myExternalLib);
                this.setState({
                    myExternalLib: window.myExternalLib
                });
            };
        }
    }

    render() {
        return (<div>
            {/* Load the myExternalLib.js library. */}
            <Helmet
                script={[{ src: "https://checkout.epayco.co/checkout.js" }]}
                // Helmet doesn't support `onload` in script objects so we have to hack in our own
                onChangeClientState={(newState, addedTags) => this.handleScriptInject(addedTags)}
            />
            <div>
                {this.state.myExternalLib !== null
                    ? ""
                    : "Cargando Epayco..."}
            </div>
            
          

     <section class="pricing-table">
        <div class="container">
            <div class="block-heading">
              <h2>FacturaDian PLANES mensuales</h2>
              <p>Ahora que ya tienes validado el software, paga un mes del plan que elijas para enviar en produccion.</p>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-md-5 col-lg-4">
                    <div class="item">
                        <div class="heading">
                            <h3>LIGHT</h3>
                        </div> 
                        <p>Para pequeñas Empresas.</p>
                        <div class="features">
                            <h4><span class="feature">Documentos</span> : <span class="value">50 al mes</span></h4>
                            <h4><span class="feature">Dolibarr-Facturación</span> : <span class="value">Si</span></h4>
                            <h4><span class="feature">Certificado Digital</span> : <span class="value">No</span></h4>
                        </div>
                        <div class="price">
                            <h3>$35.000 mes</h3>
                        </div>
                        <div class="price">
                            <center>
                                $28.000 mes Pago Anual
                            </center>
                        </div>
                                    <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='5000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan LIGHT un mes' 
                                          data-epayco-description='Plan LIGHT un mes' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest} 
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='LIGHT,mensual'
                                          data-epayco-response='http://portal.facturadian.com' 
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycomes.png'>
                                      </script> 
                                  </form>
                                  
                                  <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='336000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan LIGHT un año' 
                                          data-epayco-description='Plan LIGHT un año' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest} 
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='LIGHT,anual'
                                          data-epayco-response='http://portal.facturadian.com' 
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycoanno.png'> 
                                      </script> 
                                  </form>
                    </div>
                </div>
                <div class="col-md-5 col-lg-4">
                    <div class="item">
                        <div class="ribbon">Mas popular</div>
                        <div class="heading">
                            <h3>BASIC</h3>
                        </div>
                        <p>Para mediana Empresas.</p>
                        <div class="features">
                            <h4><span class="feature">Documentos</span> : <span class="value">100 al mes</span></h4>
                            <h4><span class="feature">Dolibarr-Facturación</span> : <span class="value">Si</span></h4>
                            <h4><span class="feature">Certificado Digital</span> : <span class="value">No</span></h4>
                        </div>
                        <div class="price">
                             <h3>$50.000 mes</h3>
                        </div>
                        <div class="price">
                            <center>
                                $40.000 mes Pago Anual
                            </center>
                        </div>
                            <form>
                              <script src='https://checkout.epayco.co/checkout.js' 
                                  data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                  class='epayco-button' 
                                  data-epayco-amount='50000' 
                                  data-epayco-tax='0'
                                  data-epayco-tax-base='0'
                                  data-epayco-name='Plan BASIC un mes' 
                                  data-epayco-description='Plan BASIC un mes' 
                                  data-epayco-currency='COP'    
                                  data-epayco-country='CO' 
                                  data-epayco-test={this.state.epaycotest}
                                  data-epayco-external='false'
                                  data-epayco-extra1={this.state.email}
                                  data-epayco-extra2={this.state.sub}
                                  data-epayco-extra3='BASIC,mensual'
                                  data-epayco-response='http://portal.facturadian.com' 
                                  data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                  data-epayco-button='https://portal.facturadian.com/epaycomes.png'> 
                              </script> 
                          </form>
                          <form>
                              <script src='https://checkout.epayco.co/checkout.js' 
                                  data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                  class='epayco-button' 
                                  data-epayco-amount='480000' 
                                  data-epayco-tax='0'
                                  data-epayco-tax-base='0'
                                  data-epayco-name='Plan BASIC un año' 
                                  data-epayco-description='Plan BASIC un año' 
                                  data-epayco-currency='COP'    
                                  data-epayco-country='CO' 
                                  data-epayco-test={this.state.epaycotest}
                                  data-epayco-external='false'
                                  data-epayco-extra1={this.state.email}
                                  data-epayco-extra2={this.state.sub}
                                  data-epayco-extra3='BASIC,anual'
                                  data-epayco-response='http://portal.facturadian.com' 
                                  data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                  data-epayco-button='https://portal.facturadian.com/epaycoanno.png'> 
                              </script> 
                          </form>
                    </div>
                </div>
                <div class="col-md-5 col-lg-4">
                    <div class="item">
                        <div class="heading">
                            <h3>STANDARD</h3>
                        </div>
                        <p>Para grandes empresas.</p>
                        <div class="features">
                            <h4><span class="feature">Documentos</span> : <span class="value">200 al mes</span></h4>
                            <h4><span class="feature">Dolibarr-Facturación</span> : <span class="value">Si</span></h4>
                            <h4><span class="feature">Certificado Digital</span> : <span class="value">No</span></h4>
                        </div>
                        <div class="price">
                              <h3>$70.000 mes</h3>
                        </div>
                        <div class="price">
                            <center>
                                $56.000 mes Pago Anual
                            </center>
                        </div>
                                    <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='70000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan STANDARD un mes' 
                                          data-epayco-description='Plan STANDARD un mes' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='STANDARD,mensual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycomes.png'>
                                      </script> 
                                  </form>
                                  <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='672000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan STANDARD un año' 
                                          data-epayco-description='Plan STANDARD un año' 
                                          data-epayco-currency='COP'
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='STANDARD,anual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycoanno.png'>  
                                      </script> 
                                  </form>
                    </div>
                </div>
                <div class="col-md-5 col-lg-4">
                    <div class="item">
                        <div class="heading">
                            <h3>PRO</h3>
                        </div>
                        <p>Para grandes empresas.</p>
                        <div class="features">
                            <h4><span class="feature">Documentos</span> : <span class="value">500 al mes</span></h4>
                            <h4><span class="feature">Dolibarr-Facturación</span> : <span class="value">Si</span></h4>
                            <h4><span class="feature">Certificado Digital</span> : <span class="value">No</span></h4>
                        </div>
                        <div class="price">
                              <h3>$110.000 mes</h3>
                        </div>
                        <div class="price">
                            <center>
                                $88.000 mes Pago Anual
                            </center>
                        </div>
                                    <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='110000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan PRO un mes' 
                                          data-epayco-description='Plan PRO un mes' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='PRO,mensual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycomes.png'>
                                      </script> 
                                  </form>
                                  <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='1056000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan PRO un año' 
                                          data-epayco-description='Plan PRO un año' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='PRO,anual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycoanno.png'> 
                                      </script> 
                                  </form>
                    </div>
                </div>
                <div class="col-md-5 col-lg-4">
                    <div class="item">
                        <div class="heading">
                            <h3>PLUS</h3>
                        </div>
                        <p>Para grandes empresas.</p>
                        <div class="features">
                            <h4><span class="feature">Documentos</span> : <span class="value">1.000 al mes</span></h4>
                            <h4><span class="feature">Dolibarr-Facturación</span> : <span class="value">Si</span></h4>
                            <h4><span class="feature">Certificado Digital</span> : <span class="value">No</span></h4>
                        </div>
                        <div class="price">
                              <h3>$160.000 mes</h3>
                        </div>
                        <div class="price">
                            <center>
                                $128.000 mes Pago Anual
                            </center>
                        </div>
                                    <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='160000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan PLUS un mes' 
                                          data-epayco-description='Plan PLUS un mes' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='PLUS,mensual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycomes.png'>  
                                      </script> 
                                  </form>
                                  <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='1536000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan PLUS un año' 
                                          data-epayco-description='Plan PLUS un año' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='PLUS,anual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycoanno.png'> 
                                      </script> 
                                  </form>
                    </div>
                </div>
                <div class="col-md-5 col-lg-4">
                    <div class="item">
                        <div class="heading">
                            <h3>PREMIUM</h3>
                        </div>
                        <p>Para grandes empresas.</p>
                        <div class="features">
                            <h4><span class="feature">Documentos</span> : <span class="value">2.000 al mes</span></h4>
                            <h4><span class="feature">Dolibarr-Facturación</span> : <span class="value">Si</span></h4>
                            <h4><span class="feature">Certificado Digital</span> : <span class="value">No</span></h4>
                        </div>
                        <div class="price">
                              <h3>$260.000 mes</h3>
                        </div>
                        <div class="price">
                            <center>
                                $208.000 mes Pago Anual
                            </center>
                        </div>
                                    <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='260000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan PREMIUM un mes' 
                                          data-epayco-description='Plan PREMIUM un mes' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='PREMIUM,mensual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycomes.png'> 
                                      </script> 
                                  </form>
                                   <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='2496000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan PREMIUM un año' 
                                          data-epayco-description='Plan PREMIUM un año' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='PREMIUM,anual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycoanno.png'> 
                                      </script> 
                                  </form>
                    </div>
                </div>
                <div class="col-md-5 col-lg-4">
                    <div class="item">
                        <div class="heading">
                            <h3>GOLD</h3>
                        </div>
                        <p>Para grandes empresas.</p>
                        <div class="features">
                            <h4><span class="feature">Documentos</span> : <span class="value">3.000 al mes</span></h4>
                            <h4><span class="feature">Dolibarr-Facturación</span> : <span class="value">Si</span></h4>
                            <h4><span class="feature">Certificado Digital</span> : <span class="value">No</span></h4>
                        </div>
                        <div class="price">
                              <h3>$300.000 mes</h3>
                        </div>
                        <div class="price">
                            <center>
                                $240.000 mes Pago Anual
                            </center>
                        </div>
                                    <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='300000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan GOLD un mes' 
                                          data-epayco-description='Plan GOLD un mes' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='GOLD,mensual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycomes.png'> 
                                      </script> 
                                  </form>
                                  <form>
                                      <script src='https://checkout.epayco.co/checkout.js' 
                                          data-epayco-key='9be75efad4c3c0f69d20f84ef0a5a343' 
                                          class='epayco-button' 
                                          data-epayco-amount='2880000' 
                                          data-epayco-tax='0'
                                          data-epayco-tax-base='0'
                                          data-epayco-name='Plan GOLD un año' 
                                          data-epayco-description='Plan GOLD un año' 
                                          data-epayco-currency='COP'    
                                          data-epayco-country='CO' 
                                          data-epayco-test={this.state.epaycotest}
                                          data-epayco-external='false'
                                          data-epayco-extra1={this.state.email}
                                          data-epayco-extra2={this.state.sub}
                                          data-epayco-extra3='GOLD,anual'
                                          data-epayco-response='http://portal.facturadian.com'  
                                          data-epayco-confirmation='https://2jr7q76fbi.execute-api.us-east-1.amazonaws.com/prod/epayco' 
                                          data-epayco-button='https://portal.facturadian.com/epaycoanno.png'> 
                                      </script> 
                                  </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    
          
          
          

        </div>);
    }
}


export default Pagar;